<template>
  <v-parallax src="material.jpg" style="width: auto; height: auto">

    <v-card class="mx-auto loginDiv" prepend-icon="mdi-account-lock">
      <template v-slot:title>Login</template>

      <v-card-text>
        <v-form validate-on="submit" @submit.prevent="login">
          <v-text-field v-model="loginForm.email"  label="Email"></v-text-field>
          <v-text-field type="password" v-model="loginForm.password" label="Password"></v-text-field>
          <v-btn type="submit" block class="mt-2" color="blue" size="large" variant="tonal">Login</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">{{ snackbarMsg }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"><v-icon icon="mdi-close-circle" /></v-btn>
      </template>
    </v-snackbar>
  </v-parallax>
</template>

<script>

export default {
  name: "Login-page",
  data: () => ({
    loginForm: {
      email: "",
      password: ""
    },
    snackbar: false,
    snackbarMsg: '',
    timeout: 3000,
    snackbarColor: "black"
  }),
  mounted() {
    /*let token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
      this.$router.push('/dashboard')
    }*/
  },
  methods: {
    login() {
      this.axios.post("/login", this.loginForm).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          localStorage.setItem("token", response.data.token)
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.$router.push('/dashboard')
        }
      }).catch(function (error) {
        this.snackbar = false
        this.snackbarMsg = error.response.data.message
        this.snackbarColor = "red"
        console.log("error: ", error);
      });

    }
  }


}
</script>
<style scoped>
.loginDiv {
  width: 400px;
  top: 30%;
}
</style>