<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer v-model="drawer" :rail="rail" permanent @click="rail = false">
        <v-list-item prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg" title="Jitendra Prajapati" nav>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="Dashboard" value="dashboard" href="/dashboard"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="My Account" value="account" href="/account"></v-list-item>
          <v-list-item prepend-icon="mdi-ballot" title="Category" value="category" href="/category"></v-list-item>
          <v-list-item prepend-icon="mdi-account-group" title="Users" value="users" href="/users"></v-list-item>
          <v-list-item prepend-icon="mdi-bell" title="Notification" value="notification" href="/notification"></v-list-item>
          <v-list-item prepend-icon="mdi-logout" title="Logout" value="logout" @click="Logout"></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar>
        <template v-slot:prepend>
          <v-app-bar-nav-icon @click.stop="rail = !rail"></v-app-bar-nav-icon>
          <v-app-bar-title>Trading Admin</v-app-bar-title>
        </template>
      </v-app-bar>

      <v-main style="min-height: 700px;">
        <slot/>
      </v-main>
    </v-layout>
  </v-card>

</template>

<script>
export default {
  name: "LayoutDefault.vue",
  data() {
    return {
      drawer: true,
      items: [
        {title: 'Home', icon: 'mdi-home-city'},
        {title: 'My Account', icon: 'mdi-account'},
        {title: 'Users', icon: 'mdi-account-group-outline'},
        {title: 'Notification', icon: 'mdi-bell'},
        {title: 'Logout', icon: 'mdi-logout-variant'},
      ],
      rail: false,
    }
  },
  methods: {
    Logout() {
      console.log("Logout")
      localStorage.clear()
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>

</style>