<template>
  <layout-default>
    <v-card prepend-icon="mdi-bell">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Notification</v-col>
          <v-col cols="auto" md="6" class="text-right">
            <v-btn icon="mdi-plus" density="comfortable" color="primary" @click="createDialog()"></v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card-text>
        <v-table fixed-header height="550px">
          <thead>
          <tr>
            <th class="text-left">Title</th>
            <th class="text-left">Message</th>
            <th class="text-left">User Type</th>
            <th class="text-left">Status</th>
            <th class="text-left">Sent at</th>
            <th class="text-left">Action</th>
          </tr>
          </thead>
          <tbody v-if="notifications.length > 0">
          <tr v-for="item in notifications" :key="item.id">
            <td>{{ item.title }}</td>
            <td>{{ item.message }}</td>
            <td>{{ item.user_type }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.created_at }}</td>
            <td>
              <v-icon icon="mdi-send" color="blue" @click="sendNotification(item.id)" v-if="item.status == 'pending' "></v-icon>
              <v-icon icon="mdi-delete" color="red" @click="deleteItem(item.id)"></v-icon>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr class="text-center">
            <td colspan="4"> Record not found.</td>
          </tr>
          </tbody>
        </v-table>

      </v-card-text>
    </v-card>

    <v-row justify="space-around">
      <v-dialog v-model="dialog" persistent width="600">
        <v-card>
          <v-toolbar color="primary" title='Send Notification'></v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Title*" required v-model="notificationForm.title"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea autocomplete="description" label="Description" rows="2"
                              v-model="notificationForm.message"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-select
                      label="User Type"
                      :items="['all', 'paid', 'unpaid']"
                      v-model="notificationForm.user_type"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange" @click="dialog = false" class="me-4 text-white">Close</v-btn>
            <v-btn color="primary" @click="create()" class="me-4 text-white">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">{{ snackbarMsg }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"><v-icon icon="mdi-close-circle" /></v-btn>
      </template>
    </v-snackbar>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/LayoutDefault.vue";
import Form from 'vform'

export default {
  name: 'CategoryList',
  components: {LayoutDefault},
  props: {
    msg: String,
  },
  data: () => ({
    dialog: false,
    notifications: [],
    notificationForm: new Form({
      title: '',
      message: '',
      user_type: 'all'
    }),
    edit: false,
    snackbar: false,
    snackbarMsg: '',
    timeout: 3000,
    snackbarColor: "black"
  }),

  created() {
    this.fetch();
  },
  computed: {

  },
  methods: {
    fetch() {
      this.edit = false
      this.axios.get("/notification").then((response) => {
        if (response.status == 200 && response.data.status) {
          this.notifications = response.data.data
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    createDialog() {
      this.notificationForm.reset();
      this.dialog = true;
    },
    create() {
      this.axios.post("/notification", this.notificationForm.data()).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.notificationForm.reset()
          this.dialog = false
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.fetch();
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    async deleteItem(id) {
      let confirm = window.confirm("Are you sure, want to delete record?")
      if (confirm) {
        await this.axios.delete(`/notification/${id}`).then((response) => {
          if (response.status == 200 && response.data.status) {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "green"
            this.fetch();
          } else {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "red"
          }
        }).catch(function (error) {
          console.log("error: ", error);
        });
      }
    },

    sendNotification(id){
      let confirm = window.confirm("Are you sure, want to send notification?")
      if (confirm) {
        this.axios.get(`/notification/${id}/send`).then((response) => {
          if (response.status == 200 && response.data.status) {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "green"
            this.fetch();
          } else {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "red"
          }
        }).catch(function (error) {
          console.log("error: ", error);
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
