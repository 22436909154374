<template>
  <layout-default>
    <v-card prepend-icon="mdi-account-group">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Users</v-col>
          <v-col cols="auto" md="6" class="text-right">
            <v-btn icon="mdi-plus" density="comfortable" color="primary" @click="$router.push('/users/create')"></v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card class="mx-auto">
        <v-card-actions>
          <v-btn color="orange-lighten-2" variant="text">
            Filter
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'" @click="show = !show"></v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>

            <v-card-text>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="searchForm.search" label="Search"></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-select label="Subscription" v-model="searchForm.subscription" :items="['all', 'paid', 'unpaid']"></v-select>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-text-field v-model="searchForm.start_date" label="Start date"></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-text-field v-model="searchForm.end_date" label="End date"></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-text-field v-model="searchForm.plan_date" label="Plan date"></v-text-field>
                    </v-col>
                    <v-col  cols="12" sm="4">
                      <v-text-field v-model="searchForm.expiry_date" label="Expiry date"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn variant="text" color="orange" @click="fetch">
                    <v-icon icon="mdi-magnify" start></v-icon>
                    Search
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>


      <v-card-text>
        <v-table fixed-header height="550px">
          <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Email</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Created At</th>
            <th class="text-left">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in categoryDetails" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.created_at }}</td>
            <td>
              <v-icon icon="mdi-eye" color="warning" @click="$router.push('users/'+item.id + '/edit')"></v-icon>
              <v-icon icon="mdi-pencil" color="primary" @click="$router.push('users/'+item.id + '/edit')"></v-icon>
              <v-icon icon="mdi-delete" color="red" @click="deleteItem(item.id)"></v-icon>
            </td>
          </tr>
          </tbody>
        </v-table>

      </v-card-text>
    </v-card>

  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/LayoutDefault.vue";

export default {
  name: 'CategoryDetailsList',
  components: {LayoutDefault},
  props: {
    msg: String,
  },
  data: () => ({
    loading: false,
    categoryDetails: [],
    searchForm:{
      search: '',
      subscription: 'all',
      start_date:'',
      end_date:'',
      plan_date:'',
      expiry_date:'',
    },
    show: false,
  }),
  created() {
    this.fetch();
  },
  computed: {
  },
  methods: {
    fetch() {
      this.axios.get("/users", {params: this.searchForm}).then((response) => {
        this.loading = false
        if (response.status == 200 && response.data.status) {
          this.categoryDetails = response.data.data
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });
    },

    update() {
      this.axios.put("/users/" + this.userForm.id, this.userForm.data()).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.userForm.reset()
          this.dialog = false
          this.edit = false
          this.fetch();
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    async deleteItem(id) {
      let confirm = window.confirm("You want to delete?")
      if (confirm) {
        await this.axios.delete("/users/" + id).then((response) => {
          if (response.status == 200 && response.data.status) {
            this.fetch();
          }
        }).catch(function (error) {
          console.log("error: ", error);
        });
      }
    }

  }
}
</script>

<style scoped>

</style>
