import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from "@/components/Dashboard.vue";
import Category from "@/components/category/index.vue";
import CategoryDetail from "@/components/category/detail.vue";
import Tradings from "@/components/trading/index.vue";
import TradingsDetail from "@/components/trading/detail.vue";
import TradingsCreate from "@/components/trading/create.vue";
import TradingsDetailEdit from "@/components/trading/edit.vue";
import Users from "@/components/users/index.vue";
import UsersCreate from "@/components/users/create.vue";
import UsersEdit from "@/components/users/edit.vue";
import Notification from "@/components/notification/index.vue";
import Account from "@/components/account/index.vue";

import Login from "@/views/Login.vue";
import axios from "axios";

let token = localStorage.getItem("token")
axios.defaults.headers.common = {'Authorization': `bearer ${token}`}

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', component: Login},
        {path: '/dashboard', component: Dashboard, meta: {requiresAuth: true}},
        {path: '/category', component: Category, meta: {requiresAuth: true}},
        {path: '/category/:id', component: CategoryDetail, meta: {requiresAuth: true}},
        {path: '/category/:categoryId/tradings', component: Tradings, meta: {requiresAuth: true}},
        {path: '/category/:categoryId/tradings/create', component: TradingsCreate, meta: {requiresAuth: true}},
        {path: '/category/:categoryId/tradings/:id', component: TradingsDetail, meta: {requiresAuth: true}},
        {path: '/category/:categoryId/tradings/:id/edit', component: TradingsDetailEdit, meta: {requiresAuth: true}},
        {path: '/users', component: Users, meta: {requiresAuth: true}},
        {path: '/users/create', component: UsersCreate, meta: {requiresAuth: true}},
        {path: '/users/:id/edit', component: UsersEdit, meta: {requiresAuth: true}},
        {path: '/notification', component: Notification, meta: {requiresAuth: true}},
        {path: '/account', component: Account, meta: {requiresAuth: true}},
    ]
})
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (token && token != 'undefined') {
            next()
            return
        }
        next('/')
    } else {
        next()
    }
})
