<template>
  <layout-default>
    <v-card prepend-icon="mdi-account-group">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Edit User</v-col>
        </v-row>
      </template>
      <form @submit.prevent="update">
        <v-card-text>
          <v-row justify="space-around">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field label="Name*" required v-model="form.name"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field label="Email*" v-model="form.email"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field label="Phone*" v-model="form.phone"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field label="Address" v-model="form.address"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="City" v-model="form.city"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="State" v-model="form.state"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field label="Pincode" v-model="form.pincode"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Country" v-model="form.country"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Plan Date" v-model="form.plan_date"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Expiry Date" v-model="form.expiry_date"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="duration" v-model="form.duration"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="subscription" v-model="form.subscription"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="note1" label="Note1" rows="2" v-model="form.note1"></v-textarea>

                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="note2" label="Note2" rows="2" v-model="form.note2"></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="note3" label="Note3" rows="2" v-model="form.note3"></v-textarea>
                </v-col>


                <v-col cols="12" md="4">
                  <v-text-field label="demo" v-model="form.demo"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-file-input label="Image" accept="image/*" show-size prepend-icon="mdi-camera"
                                @change="selectFile($event)"></v-file-input>
                </v-col>
                <v-col class="12" md="4" v-if="form.image">
                  <img :src="$backendUrl+'storage/customer/' + form.image" width="150" height="100">
                </v-col>
                <v-col class="12" md="4" v-if="previewImage">
                  <img :src="previewImage" width="150" height="100">
                </v-col>

                <v-col cols="12" class="d-flex align-content-start flex-wrap">
                  <v-spacer></v-spacer>
                  <v-btn  color="orange" class="me-4 text-white" @click="$router.push('/users')">Back</v-btn>
                  <v-btn  color="primary" class="me-4" type="submit" :loading="loading">Save</v-btn>
                </v-col>

              </v-row>
            </v-container>
          </v-row>
        </v-card-text>
      </form>
    </v-card>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/LayoutDefault.vue";
import Form from "vform";

export default {
  name: "trading_create",
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    form: new Form({
      id:null,
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      plan_date: '',
      expiry_date: '',
      duration: '',
      subscription: '',
      demo: '',
      note1: '',
      note2: '',
      note3: '',
      image: null,
    }),
    previewImage: null,
  }),
  mounted() {
    this.form.id = this.$route.params.id
    this.fetch()
  },
  methods: {

    selectFile(e) {
      this.form.image = e.target.files[0]
      if (this.form.image) {
        this.previewImage = URL.createObjectURL(this.form.image);
      }
    },

    fetch() {
      this.axios.get("/users/" + this.$route.params.id).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.form.fill(response.data.data)
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });
    },
    update() {
      this.loading = true
      this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      this.form._method = "PUT";
      this.axios.post("/users/" + this.$route.params.id, this.form.data()).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.form.reset()
          this.loading = false
          this.$router.push('/users');
        }
      }).catch(function (error) {
        this.loading = false
        console.log("error: ", error);
      });
    },
  }
}
</script>

<style scoped>

</style>