<template>
  <layout-default>
    <v-card prepend-icon="mdi-home-city" class="pb-10">
      <template v-slot:title>
        Dashboard
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-card class="mx-auto" width="400" prepend-icon="mdi-home">
              <template v-slot:title> Total Users</template>
              <v-card-text>{{ dashboard.total_users }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="mx-auto" width="400" prepend-icon="mdi-home">
              <template v-slot:title> Today Visitors</template>
              <v-card-text>{{ dashboard.visitor_users }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="mx-auto" width="400" prepend-icon="mdi-home">
              <template v-slot:title> Today New Users</template>
              <v-card-text>{{ dashboard.today_users }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="mx-auto" width="400" prepend-icon="mdi-home">
              <template v-slot:title> Total Premium Users</template>
              <v-card-text>{{ dashboard.total_paid_users }}</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="mx-auto" width="400" prepend-icon="mdi-home">
              <template v-slot:title> Today Premium Users</template>
              <v-card-text>{{ dashboard.today_paid_users }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/LayoutDefault.vue";

export default {
  name: 'HelloWorld',
  components: {LayoutDefault},
  props: {
    msg: String
  },
  data: () => ({
    dashboard: []
  }),
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.edit = false
      this.axios.get("/dashboard").then((response) => {
        if (response.status == 200 && response.data.status) {
          // console.log(response.data.data)
          this.dashboard = response.data.data
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
