import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { fa } from 'vuetify/iconsets/fa'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            fa,
            mdi,
        }
    },
})

import { router } from './router'
const app = createApp(App).use(vuetify).use(VueAxios, axios).use(router)
app.use(VueAxios, axios)

// axios.defaults.baseURL = 'http://trading-backend.localhost/api/';
// app.config.globalProperties.$backendUrl = 'http://trading-backend.localhost/'

axios.defaults.baseURL = 'https://api.liveforexforecast.com/api/';
app.config.globalProperties.$backendUrl = 'https://api.liveforexforecast.com/'

let token = localStorage.getItem("token");
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`, 'Accept': 'application/json'}
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')