<template>
  <layout-default>
    <v-card prepend-icon="mdi-tray-full">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Edit Trading</v-col>
        </v-row>
      </template>
      <form @submit.prevent="update">
        <v-card-text>
          <v-row justify="space-around">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="Name*" required v-model="form.name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Name2*" required v-model="form.name2"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Url" v-model="form.url"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field label="Link" v-model="form.link"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field label="Stop Loss1" v-model="form.stop_loss1"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Stop Loss2" v-model="form.stop_loss2"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Stop Loss3" v-model="form.stop_loss3"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field label="Target1" v-model="form.target1"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Target2" v-model="form.target2"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="target3" v-model="form.target3"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea autocomplete="description" label="Description" rows="2"
                              v-model="form.description"></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="note1" label="Note1" rows="2" v-model="form.note1"></v-textarea>

                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="note2" label="Note2" rows="2" v-model="form.note2"></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="note3" label="Note3" rows="2" v-model="form.note3"></v-textarea>
                </v-col>


                <v-col cols="12" md="4">
                  <v-textarea autocomplete="reminder1" label="Reminder1" rows="2" v-model="form.reminder1"></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="reminder2" label="Reminder2" rows="2" v-model="form.reminder2"></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-textarea autocomplete="reminder3" label="Reminder3" rows="2" v-model="form.reminder3"></v-textarea>
                </v-col>

                <v-col cols="12" md="4">
                  <v-file-input label="Image1" accept="image/*" show-size prepend-icon="mdi-camera"
                                @change="selectFile($event, 1)"></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-file-input label="Image2" accept="image/*" show-size prepend-icon="mdi-camera"
                                @change="selectFile($event, 2)"></v-file-input>
                </v-col>
                <v-col cols="12" md="4">
                  <v-file-input label="Image3" accept="image/*" show-size prepend-icon="mdi-camera"
                                @change="selectFile($event, 3)"></v-file-input>
                </v-col>
                <v-col class="12" md="4" v-if="previewImage1">
                  <img :src="previewImage1" width="150" height="100">
                </v-col>
                <v-col class="12" v-else-if="form.image1">
                  <img :src="$backendUrl+'storage/category/details/' + form.image1" width="150" height="100">
                </v-col>

                <v-col class="12" md="4" v-if="previewImage2">
                  <img :src="previewImage2" width="150" height="100">
                </v-col>
                <v-col class="12" v-else-if="form.image2">
                  <img :src="$backendUrl+'storage/category/details/' + form.image2" width="150" height="100">
                </v-col>

                <v-col class="12" md="4" v-if="previewImage3">
                  <img :src="previewImage3" width="150" height="100">
                </v-col>
                <v-col class="12"  v-else-if="form.image3">
                  <img :src="$backendUrl+'storage/category/details/' + form.image3" width="150" height="100">
                </v-col>

                <v-col cols="12" class="d-flex align-content-start flex-wrap">
                  <v-spacer></v-spacer>
                  <v-btn color="orange" class="me-4 text-white"
                         @click="$router.push('/category/'+ $route.params.categoryId +'/tradings')">Back
                  </v-btn>
                  <v-btn color="primary" class="me-4" type="submit" :loading="loading">Save</v-btn>
                </v-col>


              </v-row>
            </v-container>
          </v-row>
        </v-card-text>
      </form>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">{{ snackbarMsg }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"><v-icon icon="mdi-close-circle" /></v-btn>
      </template>
    </v-snackbar>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/LayoutDefault.vue";
import Form from "vform";

export default {
  name: "trading_create",
  components: {LayoutDefault},
  data: () => ({
    loading: false,
    form: new Form({
      id: null,
      category_id: null,
      name: '',
      name2: '',
      url: '',
      link: '',
      stop_loss1: '',
      stop_loss2: '',
      stop_loss3: '',
      target1: '',
      target2: '',
      target3: '',
      description: '',
      note1: '',
      note2: '',
      note3: '',
      reminder1: '',
      reminder2: '',
      reminder3: '',
      image1: null,
      image2: null,
      image3: null,
    }),
    previewImage1: null,
    previewImage2: null,
    previewImage3: null,
    snackbar: false,
    snackbarMsg: '',
    timeout: 3000,
    snackbarColor: "black"
  }),
  mounted() {
    this.form.category_id = this.$route.params.categoryId
    this.form.id = this.$route.params.id
    this.fetch()
  },
  methods: {

    fetch() {
      this.axios.get("/category/" + this.$route.params.categoryId + '/detail/' + this.$route.params.id).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.form.fill(response.data.data)
        }
      }).catch(function (error) {
        this.snackbar = true
        this.snackbarMsg = error
        this.snackbarColor = "red"
        console.log("error: ", error);
      });
    },
    selectFile(e, imageId) {
      if (imageId == 1) {
        this.form.image1 = e.target.files[0]
        if (this.form.image1) {
          this.previewImage1 = URL.createObjectURL(this.form.image1);
        }
      }

      if (imageId == 2) {
        this.form.image2 = e.target.files[0]
        if (this.form.image2) {
          this.previewImage2 = URL.createObjectURL(this.form.image2);
        }
      }

      if (imageId == 3) {
        this.form.image3 = e.target.files[0]
        if (this.form.image3) {
          this.previewImage3 = URL.createObjectURL(this.form.image3);
        }
      }
    },
    update() {
      this.loading = true
      this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      this.form._method = "PUT";
      this.axios.post("/category/" + this.$route.params.categoryId + '/detail/' + this.$route.params.id, this.form.data()).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.form.reset()
          this.loading = false
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.$router.push('/category/' + this.$route.params.categoryId + '/tradings');
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        this.loading = false
        this.snackbar = true
        this.snackbarMsg = error
        this.snackbarColor = "red"
        console.log("error: ", error);
      });
    },
  }
}
</script>

<style scoped>

</style>