<template>
  <layout-default>

    <v-card prepend-icon="mdi-account" class="pb-10">
      <template v-slot:title>
        My Account
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-card class="mx-auto" width="600" title="Edit details">
              <v-card-text>
                <v-form validate-on="submit">
                  <v-text-field disabled v-model="changePasswordForm.email"  label="Email"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn variant="text" color="orange-lighten-2" @click="reveal = reveal? false : true">
                  Change password
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <v-card v-if="reveal" class="v-card--reveal" style="height: 100%;">
                  <v-card-text class="pb-0">
                    <v-form validate-on="submit">
                      <v-text-field type="password" v-model="changePasswordForm.old_password"
                                    label="Old Password" autocomplete="off"></v-text-field>
                      <v-text-field type="password" v-model="changePasswordForm.password"
                                    label="Password" autocomplete="off"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn variant="text" color="teal-accent-4" @click="changePassword">
                      Change
                      <v-icon icon="mdi-chevron-right" end></v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>

      <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">{{ snackbarMsg }}
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="snackbar = false"><v-icon icon="mdi-close-circle" /></v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/LayoutDefault.vue";

export default {
  name: 'MyAccount',
  components: {LayoutDefault},
  props: {
    msg: String
  },
  data: () => ({
    dashboard: [],
    user: [],
    changePasswordForm: {
      email: "",
      password: "",
      old_password: "",
    },
    reveal: false,
    snackbar: false,
    snackbarMsg: '',
    timeout: 3000,
    snackbarColor: "black"
  }),
  created() {
    this.activeUser();
  },
  methods: {
    activeUser() {
      this.axios.get("/user").then((response) => {
        if (response.status == 200 && response.data.status) {
          this.user = response.data.data
          this.changePasswordForm.email = this.user.email
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },
    changePassword() {
      this.axios.post("/user/change-passowrd", this.changePasswordForm).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.reveal = false
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        this.snackbar = true
        this.snackbarMsg = error.response.data.message
        this.snackbarColor = "red"
        console.log("error: ", error);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
