<template>
  <layout-default>
    <v-card prepend-icon="mdi-ballot">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Details</v-col>
        </v-row>
      </template>
    </v-card>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/LayoutDefault.vue";

export default {
  name: "categoryDetail",
  components: {LayoutDefault},
  data: () => ({
    category: {}
  }),
  created() {
    console.log("created")
    let categoryId = this.$route.params.id
    this.fetch(categoryId);
  },
  methods: {
    fetch(categoryId) {
      this.axios.get("/category/" + categoryId).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.category = response.data.data
          console.log(this.category)
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });
    },
  }
}
</script>

<style scoped>

</style>