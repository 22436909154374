<template>
  <layout-default>
    <v-card prepend-icon="mdi-tray-full">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Tradings</v-col>
          <v-col cols="auto" md="6" class="text-right">
            <v-row class="d-flex justify-end">
              <v-col cols="auto">
                <v-btn  density="comfortable" color="primary" @click="$router.push('/category/'+ $route.params.categoryId +'/tradings/create')">create</v-btn>
              </v-col>
              <v-col cols="auto">
                <input type="file" ref="file" style="display: none"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="importFile"/>
                <v-btn  density="comfortable" color="teal-accent-4" @click="$refs.file.click()" :loading="importLoading">Import</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <v-card-text>
        <v-table fixed-header height="550px">
          <thead>
          <tr>
            <th class="text-left">Id</th>
            <th class="text-left">Name</th>
            <th class="text-left">Name2</th>
            <th class="text-left">Url</th>
            <th class="text-left">Link</th>
            <th class="text-left">Description</th>
            <th class="text-left">Action</th>
          </tr>
          </thead>
          <tbody v-if="categoryDetails.length > 0">
          <tr v-for="item in categoryDetails" :key="item.name">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.name2 }}</td>
            <td>{{ item.url }}</td>
            <td>{{ item.link }}</td>
            <td>{{ item.description }}</td>
            <td>
              <v-icon icon="mdi-eye" color="warning" @click="$router.push('/category/'+ $route.params.categoryId +'/tradings/'+item.id + '/edit')"></v-icon>
              <v-icon icon="mdi-pencil" color="primary" @click="$router.push('/category/'+ $route.params.categoryId +'/tradings/'+item.id + '/edit')"></v-icon>
              <v-icon icon="mdi-delete" color="red" @click="deleteItem(item.id)"></v-icon>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center">
              <td colspan="6"> Record not found.</td>
            </tr>
          </tbody>
        </v-table>

      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">{{ snackbarMsg }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"><v-icon icon="mdi-close-circle" /></v-btn>
      </template>
    </v-snackbar>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/LayoutDefault.vue";

export default {
  name: 'CategoryDetailsList',
  components: {LayoutDefault},
  props: {
    msg: String,
  },
  data: () => ({
    loading: false,
    categoryDetails: [],
    snackbar: false,
    snackbarMsg: '',
    timeout: 3000,
    snackbarColor: "black",
    importLoading: false
  }),
  created() {
    this.fetch();
  },
  computed: {
  },
  methods: {
    fetch() {
      this.axios.get("/category/" + this.$route.params.categoryId + '/detail').then((response) => {
        this.loading = false
        if (response.status == 200 && response.data.status) {
          this.categoryDetails = response.data.data
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });
    },

    update() {
      this.axios.put("/category/" + this.categoryForm.id, this.categoryForm.data()).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.categoryForm.reset()
          this.dialog = false
          this.edit = false
          this.fetch();
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    async deleteItem(id) {
      let confirm = window.confirm("You want to delete?")
      if (confirm) {
        await this.axios.delete("/category/" + this.$route.params.categoryId + '/detail/' + id).then((response) => {
          if (response.status == 200 && response.data.status) {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "green"
            this.fetch();
          } else {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "red"
          }
        }).catch(function (error) {
          console.log("error: ", error);
        });
      }
    },

    importFile(e) {
      this.importLoading = true
      let file = e.target.files[0]
      this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      this.axios.post("/category/"+ this.$route.params.categoryId + "/detail/import" , {file:file}).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.fetch();
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });
      this.importLoading = false
    }

  }
}
</script>

<style scoped>

</style>
